import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRelatedAds } from "../utils/EndPoint";

const SingleAd = (props) => {
  const [boxLoader, setBoxLoader] = useState(true);
  const [adsList, setAdList] = useState([]);

  const fetchRelatedAds = async () => {
    setBoxLoader(true);
    try {
      const relatedAds = await getRelatedAds(localStorage?.getItem("token"));
      if (relatedAds !== null || relatedAds?.length > 0) {
        setAdList(relatedAds);
        setBoxLoader(false);
      } else {
        setBoxLoader(true);
      }
    } catch (error) {
      console.error("Error fetching active plan:", error);
      setBoxLoader(true);
    }
  };

  useEffect(() => {
    fetchRelatedAds();
  }, []);

  return (
    <>
      <aside
        className="ads-view px-xl-3 px-lg-2 px-md-2 px-sm-2"
        aria-label="related-images"
      >
        {boxLoader ? (
          <>
            <ul className="list-unstyled m-0 p-0 d-flex flex-column gap-3">
              <li className="shimmer-box box-192 mb-3"></li>
              <li className="shimmer-box box-192 mb-3"></li>
              <li className="shimmer-box box-192 mb-2p7"></li>
            </ul>
          </>
        ) : (
          adsList?.map((data, index) => {
            return (
              <div
                className={` ${
                  index !== 2 ? "mb-xl-3 mb-lg-2 mb-2" : "mb-2p7"
                }`}
                key={index}
              >
                <Link
                  to={data?.Vburl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ad-imgs"
                >
                  <img
                    src={data?.url}
                    aria-label="related-search-result"
                    alt="related-search-result"
                    className="img-responsive ad-img"
                    height={192}
                    width={192}
                  />
                </Link>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">
                    <Link
                      to={data?.Vburl}
                      target="_blank"
                      className="inherit-color text-decoration-none"
                    >
                      Virtual Booth
                    </Link>
                  </p>
                  {/* <p>|</p> */}
                  <p className="mb-0 isDisabled">
                    <Link
                      to={"#"}
                      className="inherit-color text-decoration-none"
                    >
                      Contact
                    </Link>
                  </p>
                </div>
              </div>
            );
          })
          // null
        )}
      </aside>
    </>
  );
};
export default SingleAd;
