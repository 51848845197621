// export const  = require('../assets/')
export const pill = require('../assets/imgs/pill.png');
export const Regenarate = require('../assets/imgs/regenrate.png');
export const Doller = require('../assets/imgs/doller.png');
export const At = require('../assets/imgs/at.png');
export const EyeOn = require('../assets/imgs/view.png');
export const EyeOff = require('../assets/imgs/blind.png');
export const Logout = require('../assets/imgs/logout.svg');
export const Lock = require('../assets/imgs/password.png');
export const SupportImg = require('../assets/imgs/support.webp');
// export const Pills = require('../assets/imgs/side-icon/pill.png');


// export const InfoIcon = require('../assets/imgs/info.svg');
export const PcAssitant ='https://www.pharmacompass.com/image/pc-blue-orange-logo-star-200x200.png';
export const Mailer = 'https://www.pharmacompass.com/image/hp-icon/at.svg?';
export const xlsIcon = 'https://www.pharmacompass.com/image/xls-icon.svg?v=2';
export const shareIcon ='https://www.pharmacompass.com/image/hp-icon/share.svg?v=2';