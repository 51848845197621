import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Closeicon } from "../SvgIcon";
import { AlertPopup2 } from "../AlertPopup";
import ReCAPTCHA from "react-google-recaptcha";
import { API_BASE_LINK } from "../../../utils/globalFunctions";

const AskUsModal = ({ setAskusModal }) => {
  const [secondaryAlert, setSecondaryAlert] = useState(false);
  const [hideFirstModal, setHideFirstmodal] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  
  const handleAskUs = (event) => {
    const formData = new FormData(event?.currentTarget);
    event.preventDefault();
    fetch(`${API_BASE_LINK}/askusArea`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: localStorage?.getItem("token"),
      },
      body: JSON.stringify({
        userName: formData?.get("userName"),
        mail: formData?.get("mail"),
        phone: formData?.get("phone"),
        askusArea: formData?.get("askusArea"),
        companyName: formData?.get("companyName"),
        captcha: "optional",
        newsletterYes: formData?.get("newsletterYes") === "on" ? true : false,
        acceptsPrivacyPolicy:
          formData?.get("acceptsPrivacyPolicy") === "on" ? true : false,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        response.json();
        console.log((res) => res?.json());
      })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  };

  const handleAskUsForm = (event) => {
    event.preventDefault();
    handleCaptchaChange();
    handleAskUs(event);
    setAskusModal(true);
    setHideFirstmodal(true);
    setSecondaryAlert(true);
  };
  return (
    <>
      {hideFirstModal === false && (
        <>
          <div className="">
            <div className="modal-container">
              <div className="">
                <button
                  aria-label="close"
                  className="close-btn-x hover-change"
                  onClick={() => setAskusModal(false)}
                >
                  <Closeicon />
                </button>
              </div>
              <div className="modal-outer modal-md">
                <div className="modal-head text-center pb-0">
                  <figure>
                    <img
                      src="https://www.pharmacompass.com/image/vb/pharmacompass-blue.svg?v=4"
                      alt="logo"
                      className=""
                      width={316}
                    />
                  </figure>
                </div>
                <div className="blue-stripe text-center">
                  <p className="mb-0">{`Ask Us +91(0)96 437 37 677 `}</p>
                </div>
                <div className="modal-body">
                  <div className="ask-us-modal">
                    <p className="mb-2 mt2_">
                      <b>How can we help?</b>
                    </p>
                    <form onSubmit={(e) => handleAskUsForm(e)} className="">
                      <div className="d-flex gap-3">
                        <div className="w-100 helvetica-c">
                          <textarea
                            name="askusArea"
                            id="askusArea"
                            className="w-100 helvetica-c"
                            rows="18"
                          ></textarea>
                        </div>
                        <div className="d-flex flex-column align-items-start gap-3 w-100">
                          <>
                            <input
                              className="w-100 helvetica-c"
                              type="text"
                              name="userName"
                              placeholder="Name"
                            />
                          </>
                          <>
                            <input
                              className="w-100 helvetica-c"
                              type="text"
                              name="companyName"
                              placeholder="Company"
                            />
                          </>
                          <>
                            <input
                              className="w-100 helvetica-c"
                              type="email"
                              name="mail"
                              placeholder="Email"
                            />
                          </>
                          <>
                            <input
                              className="w-100 helvetica-c"
                              type="number"
                              name="phone"
                              placeholder="Mobile"
                            />
                          </>
                          <ReCAPTCHA
                            size="normal"
                            sitekey="6LcVopkqAAAAAKT41Ez2rngglCRhgVFS3CQBq9wY"
                            onChange={console.log("")}
                          />
                          <div className="">
                            <div className="d-flex gap-1 justify-content-end flex-row-reverse mb-2">
                              <label htmlFor="newsletterYes">
                                Yes, I would like to recieve the newsletter
                              </label>
                              <input
                                type="checkbox"
                                name="newsletterYes"
                                id="newsletterYes"
                              />
                            </div>
                            <Link
                              to={`https://www.pharmacompass.com/privacy-policy`}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="d-block mb-2 link-color"
                            >
                              Please read our privacy policy carefully.
                            </Link>
                            <div className="d-flex gap-1 justify-content-end flex-row-reverse mb-2">
                              <label htmlFor="acceptsPrivacyPolicy">
                                I accept the Privacy Policy
                              </label>
                              <input
                                type="checkbox"
                                name="acceptsPrivacyPolicy"
                                id="acceptsPrivacyPolicy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="d-flex btn btn-orange ms-auto justify-content-center"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlay-fill"></div>
        </>
      )}

      {secondaryAlert && (
        <>
          <AlertPopup2
            title={<h3 className="mb-0">Thank you for contacting us.</h3>}
            buttonName="Close"
            setAlertPopup={setAskusModal}
            submitFunction={() => setAskusModal(false)}
            hasBg={true}
            content={
              <p className="lh-base" style={{ color: "var(--textGray)" }}>
              Thank you for getting in touch with us!<br/> We'll get back to you as soon as we can.
              </p>
            }
          />
          <div
            className="overlay-fill"
            onClick={() => setAskusModal(false)}
          ></div>
        </>
      )}
    </>
  );
};
export default AskUsModal;
