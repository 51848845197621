import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Airesponse from "./AiResponse";
import { PC_API_LINK } from "../utils/globalFunctions";
import { AlertPopup } from "../components/ui-component/AlertPopup";
import {
  ChevDown,
  PcRobo,
  PcRobo2,
  Send,
} from "../components/ui-component/SvgIcon";
import PrePopulatedQus from "./questions/PrePopulatedQus";
import SearchHistory from "./SearchHistory";
import { RelatedQuestion } from "./questions/RelatedQuestions";
import {
  Exhaustion,
  FreePlanExhaust,
} from "../components/ui-component/popups/Modals";
import { useApi } from "../utils/ApiContext";
import { getActivePlan } from "../utils/EndPoint";

const QusAns = ({
  chatData,
  clickedId,
  qusContext,
  contextType,
  // setHistory,
  // history,
  setActiveIndex,
  setChatData,
  setClickedId,
  placeholderText,
  setPlaceholderText,
  fetchHistoryData,
  setIsLoggedIn,
}) => {
  const [hovered, setHovered] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [chatId, setChatId] = useState("");
  const [messages, setMessages] = useState([]);
  const [msgJson, setMsgJson] = useState();
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [activeArea, setActiveArea] = useState(true);
  const [planName, setPlanName] = useState();

  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const { getChatHistory, history, setHistory, userLoggedIn, token } = useApi();

  useLayoutEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    };
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const userLoggedIn = !!localStorage?.getItem("email");
    setActiveArea(!userLoggedIn || isFetching);
  }, [isFetching]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          chatContainerRef.current;
        setShowScrollToBottom(scrollTop < scrollHeight - clientHeight - 400);
      }
    };
    const container = chatContainerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (chatData) {
      const updatedMessages = chatData
        ?.map((msg) => [
          { text: msg?.user_question, isUser: true },
          {
            text: msg?.assistant_response,
            isUser: false,
            usr_qsn: msg?.user_question,
            query: msg?.query,
          },
        ])
        .flat();
      setMessages(updatedMessages);
      setChatId(chatData?.clickedId ? chatData?.clickedId : chatId);
      setMsgJson(chatData);
    }
  }, [chatData]);

  const fetchUserActivePlan = async () => {
    try {
      const activePlanData = await getActivePlan(userLoggedIn, token);
      if (activePlanData[0]?.plan_id === null) {
        setPlanName(activePlanData[0]);
      }
    } catch (error) {
      console.error("Error fetching active plan:", error);
    }
  };

  useEffect(() => {
    fetchUserActivePlan();
  }, []);

  const handleSubmit = async (e, usrQsn) => {
    if (planName?.plan_status === false) {
      setPrompt("");
      return;
    }
    e.preventDefault();
    const promptToUse = usrQsn || prompt;

    if (!promptToUse) {
      setAlertPopup(true);
      return;
    }
    setPlaceholderText("Ask a followup question");
    setPrompt("");
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: promptToUse, isUser: true },
      {
        text: "Assistant is typing",
        isUser: false,
        isTyping: true,
        usr_qsn: promptToUse,
      },
    ]);

    setIsFetching(true);

    try {
      const res = await fetch(
        `${PC_API_LINK}/${qusContext === "" ? "chat" : qusContext}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage?.getItem("token"),
          },
          body: JSON.stringify({
            username: localStorage?.getItem("email"),
            type: contextType,
            prompt: promptToUse,
            chat_id:
              clickedId && clickedId !== undefined && clickedId !== ""
                ? clickedId
                : !clickedId && chatId
                ? chatId
                : "",
          }),
        }
      );
      const data = await res.json();
      setMsgJson(data);
      setChatId(data?.chat_id);
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        {
          text: data?.assistant,
          isUser: false,
          query: data?.query,
          usr_qsn: promptToUse,
        },
      ]);
      fetchUserActivePlan();
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { text: "Error fetching response", isUser: false },
      ]);
    } finally {
      setIsFetching(false);
      setTimeout(() => {
        getChatHistory(contextType);
        if (chatId === "") {
          setActiveIndex(0);
        }
      }, 2000);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const cursorOn = () => {
    document?.getElementById("user-query-input").focus();
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div
          className="messages-container ai-responses pt-3"
          ref={chatContainerRef}
        >
          {messages?.map((msg, index) => (
            <Airesponse
              key={index}
              message={msg?.text}
              usr_qus={msg?.usr_qsn}
              query={msg?.query}
              isUser={msg?.isUser}
              triggerFunction={handleSubmit}
              clickedId={clickedId}
              dataJson={msgJson}
              isFetching={isFetching}
              contextType={contextType}
              chatData={chatData}
            />
          ))}
          {isFetching === false && messages?.length > 0 && <RelatedQuestion />}
          {messages?.length === 0 && (
            <div className="text-center placeholder-ai">
              <div
                className="d-inline-block"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                {hovered ? <PcRobo2 /> : <PcRobo />}
              </div>
              <PrePopulatedQus />
            </div>
          )}
          <div className="mt-5" ref={messagesEndRef} />
        </div>

        {/* scroll-to bottom start */}
        {showScrollToBottom && (
          <button
            type="button"
            aria-label="scroll to top"
            onClick={scrollToBottom}
            className="back-to-bottom cursor-pointer"
          >
            <ChevDown />
          </button>
        )}
        {/* scroll-to bottom ends */}

        <div className="user-query-n px-lg-3 px-md-2 px-sm-2 pb-3">
          <Exhaustion planName={planName} setIsLoggedIn={setIsLoggedIn} />

          <div className="search-area d-flex mx-auto position-relative">
            <SearchHistory
              setHistory={setHistory}
              history={history}
              setChatData={setChatData}
              func={cursorOn}
              setClickedId={setClickedId}
              setPlaceholderText={setPlaceholderText}
              fetchHistoryData={fetchHistoryData}
              contextType={contextType}
              clickedId={clickedId}
            />
            <form
              id="user-query"
              onSubmit={handleSubmit}
              className="d-flex w-100 d-block border-0 rounded-pill "
            >
              <textarea
                id="user-query-input"
                className={`border-top-0 ${activeArea ? "cursor-no-drop" : ""}`}
                placeholder={placeholderText}
                value={prompt}
                onKeyDown={handleKeyDown}
                onChange={(e) => setPrompt(e.target.value)}
                disabled={activeArea}
                rows={1}
              ></textarea>
              <button
                aria-label="send query"
                type="submit"
                className={`${
                  prompt?.length === undefined ||
                  (prompt?.length === 0 && "isDisabled-clear")
                } border-0 bg-transparent border-top-0 cursor-pointer me-0`}
                disabled={
                  activeArea ||
                  prompt?.length === undefined ||
                  (prompt?.length === 0 && true)
                }
              >
                <Send
                  activeArea={`ms-2 me-0 ${activeArea ? "disabled-field" : ""}`}
                />
              </button>
            </form>
          </div>
        </div>
      </div>
      {alertPopup && (
        <AlertPopup
          title={`Alert`}
          content={`Please enter some query first.`}
          setAlertPopup={setAlertPopup}
          setSelectedRows={[]}
          submitFunction={() => setAlertPopup(false)}
        />
      )}
    </>
  );
};

export default QusAns;
