const apiPath = "https://www.pharmacompass.com";

export const getActivePlan = (userLoggedIn, token) => {
  return fetch(`${apiPath}/subscribed_plan`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      username: userLoggedIn,
    }),
  })
    .then((response) => response.json())
    .then((data) => data?.subscribed_plan);
};

export const getRelatedAds = (token) => {
  return fetch(`${apiPath}/relatedAds`, {
    method: "GET",
    headers: { Authorization: token },
  })
    .then((response) => response.json())
    .then((data) => data?.related_ads);
};

export const getSuggestedQuestionList = (token) => {
  return fetch(`${apiPath}/pre_populated_qus`, {
    method: "GET",
    headers: { Authorization: token },
  })
    .then((response) => response.json())
    .then((data) => data?.PRE_POPULATED_QUS);
};

export const fetchThumbStar = (
  clickedId,
  contextType,
  starred,
  thumbsUp,
  thumbsDown,
  token,
  badFeedback
) => {
  return fetch(`${apiPath}/update_params`, {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: token },
    body: JSON.stringify({
      chat_id: clickedId,
      type: contextType,
      star: starred,
      thumbs_up: thumbsUp,
      thumbs_down: thumbsDown,
      feedback: badFeedback,
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log());
};

export const fetchProfile = (token) => {
  return fetch(`${apiPath}/get_profile`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: token,
    },
    body: JSON.stringify({
      token: "YWxhZGRpbjpvcGVuc2VzYW1l|android",
      email: localStorage?.getItem("email"),
    }),
  })
    .then((response) => response?.json())
    .then((data) => data[0]);
};

export const handleCustomiseModel = (userLoggedIn, token) => {
  return fetch(`${apiPath}/customize_list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: token,
    },
    body: JSON.stringify({
      username: userLoggedIn,
    }),
  })
    .then((response) => response?.json())
    .then((data) => data);
};
