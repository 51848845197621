import React, { useEffect, useState } from "react";
import { Closeicon } from "../SvgIcon";
import { API_BASE_LINK } from "../../../utils/globalFunctions";
import { useApi } from "../../../utils/ApiContext";

const SubscriptionPlanList = ({ setSubscriptionPlan, setActivePlan }) => {
  const [planLoader, setPlanLoader] = useState(true);
  const [planList, setPlanList] = useState({});
  const [planSelected, setPlanSelected] = useState(0);

  const { userLoggedIn } = useApi();

  const getAllSubscriptionPlans = () => {
    setPlanLoader(true);
    fetch(`${API_BASE_LINK}/subscription_plan_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: localStorage?.getItem("token"),
      },
      body: JSON.stringify({
        username: userLoggedIn,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data !== null || data !== undefined) {
          setPlanList(data);
          setPlanLoader(false);
        } else {
          setPlanLoader(true);
        }
      });
  };

  // const getAllSubscriptionPlans = () => {
  //   setPlanLoader(true);
  //   const storedPlans = sessionStorage.getItem("subscriptionPlans");

  //   if (storedPlans) {
  //     setPlanList(JSON.parse(storedPlans));
  //     setPlanLoader(false);
  //   } else {
  //     fetch(`${API_BASE_LINK}/subscription_plan_list`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         Authorization: localStorage?.getItem("token"),
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data !== null && data !== undefined) {
  //           sessionStorage.setItem("subscriptionPlans", JSON.stringify(data));
  //           setPlanList(data);
  //           setPlanLoader(false);
  //         } else {
  //           setPlanLoader(true);
  //         }
  //       });
  //   }
  // };

  const thisPlanSelected = (id) => {
    console.log(id);
    setPlanSelected(id);
  };

  useEffect(() => {
    getAllSubscriptionPlans();
  }, []);
  return (
    <>
      <div className="modal-full-view">
        <div className="modal-full-view-inner theme-color">
          <div className="close-mx">
            <button
              aria-label="close"
              className="close-btn-x hover-change"
              onClick={() => {
                setSubscriptionPlan(false);
                setActivePlan(false);
              }}
            >
              <Closeicon />
            </button>
          </div>
          <div className="subscriptions-plan">
            {planLoader ? (
              <>
                <div className="shimmer-flat"></div>
                <ul className="list-unstyled p-0 d-flex gap-4">
                  <li className="shimmer-box"></li>
                  <li className="shimmer-box"></li>
                  <li className="shimmer-box"></li>
                  <li className="shimmer-box"></li>
                </ul>
                <br />
                <div className="shimmer-flat shimmer-flat-230"></div>
              </>
            ) : (
              <>
                <h1 className="text-center mb-4 helvetica">
                  Subscription Plans
                </h1>
                <div className="">
                  <div className="d-flex flex-wrap gap-3">
                    {planList?.length !== 0 &&
                      planList?.subscription_plans?.map((data, index) => {
                        return (
                          <div
                            className={`${
                              data?.plan_status ? "purchased-plan" : ""
                            } active-subscription-plan active-subscription-plan-mini text-center ${
                              planSelected === index + 1 ? "selected-plan" : ""
                            }`}
                            key={index}
                          >
                            <h2 className="plan-name">{data?.plan_title}</h2>
                            <p className="amount gap-1">
                              <span> {data?.plan_per_month} </span>
                            </p>
                            <h2 className="mini-question-left mb-3">
                              {data?.no_of_questions}
                            </h2>
                            {/* <button
                              className={`btn w-100 hover-o ${
                                planSelected === index + 1 || data?.plan_status
                                  ? "btn-orange"
                                  : "btn-theme"
                              }`}
                              onClick={() => thisPlanSelected(index + 1)}
                            >
                              {data?.plan_status ? (
                                <>Selected</>
                              ) : planSelected === index + 1 ? (
                                <>Selected</>
                              ) : (
                                <>Select</>
                              )}
                            </button> */}
                            <button
                              className={`btn w-100 hover-o ${
                                data?.plan_status ? "btn-orange" : "btn-theme"
                              }`}
                              onClick={() => thisPlanSelected(index + 1)}
                            >
                              {data?.plan_status ? <>Selected</> : <>Select</>}
                            </button>
                          </div>
                        );
                      })}
                  </div>
                  <div className="points-table border">
                    <h1 className="text-center mb-4 helvetica">
                      How Pharmapoints Work
                    </h1>
                    <table className="w-100" border={0}>
                      <tbody>
                        {planList?.how_pharmapoints_work?.map((data, index) => (
                          <tr key={index}>
                            <td className={`data-${index}-1 td-data`}>
                              {data?.action}
                            </td>
                            <td className={`data-${index}-2 td-data`}>
                              {data?.points}
                            </td>
                            <td className={`data-${index}-3 td-data`}>
                              {data?.frequency}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="overlay-fill"
          // onClick={() => setClearHistory(false)}
        ></div>
      </div>
    </>
  );
};
export default SubscriptionPlanList;
