import React, { useEffect, useState } from "react";
import { Closeicon } from "../SvgIcon";

export const ActiveSubscriptionPlan = ({
  activePlanDetail,
  setActivePlan,
  setSubscriptionPlan,
}) => {
  return (
    <>
      <div className="">
        <div className="modal-container">
          <div className="">
            <button
              aria-label="close"
              className="close-btn-x hover-change"
              onClick={() => setActivePlan(false)}
            >
              <Closeicon />
            </button>
          </div>
          <div className="modal-outer modal-sm">
            <div className="modal-head text-center pb-0">
              <figure>
                <img
                  src="https://www.pharmacompass.com/image/vb/pharmacompass-blue.svg?v=4"
                  alt="logo"
                  className=""
                  width={316}
                />
              </figure>
            </div>
            <div className="blue-stripe"></div>
            <div className="modal-body">
              <div className="active-subscription-plan text-center">
                {activePlanDetail?.length > 0
                  ? activePlanDetail?.map((data, index) => {
                      return (
                        <div key={index}>
                          <h2 className="plan-name">{data?.plan_title}</h2>

                          <p className="amount gap-1">
                            <span> {data?.plan_per_month} </span>
                          </p>
                          <h2 className="question-left">
                            {data?.questions_allowed}
                          </h2>
                        </div>
                      );
                    })
                  : null}
              </div>
              <button
                className="btn btn-theme mx-auto d-block my-4 mb-2 min-w-216"
                onClick={() => setSubscriptionPlan(true)}
              >
                Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay-fill" onClick={() => setActivePlan(false)}></div>
    </>
  );
};
