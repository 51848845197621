import React, { useEffect, useState } from "react";
import { OutlinedButton } from "../../components/ui-component/buttons/ButtonType";
import { getSuggestedQuestionList } from "../../utils/EndPoint";
import AssistantLoader2 from "../../components/ui-component/AssistantLoader2";

const PrePopulatedQus = () => {
  const [loader, setLoader] = useState(true);
  const [suggestionList, setSuggestionList] = useState({});

  const fetchSuggestionList = async () => {
    setLoader(true);
    try {
      const suggestionListData = await getSuggestedQuestionList(localStorage?.getItem("token"));
      if (suggestionListData !== null || suggestionListData.length > 0) {
        setSuggestionList(suggestionListData);
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(true);
    }
  };
  useEffect(() => {
    fetchSuggestionList();
  }, []);

  const clickFunc = (question, contextName, type, chatId) => {
    console.log(question, contextName, type, chatId);
  };
  return (
    <>
      <div className="d-flex flex-column">
        <p className="my-3 mb-2 font-helvetica-condensed">PharmaCompass.AI</p>
        <h1 className="mb-4 font-helvetica-condensed">
          What will you discover?
        </h1>
        {loader ? (
          <AssistantLoader2 />
        ) : (
          <div className="pre-populated-question pre-populated-question-2">
            {suggestionList?.map((item, index) => {
              return (
                <OutlinedButton
                  key={index}
                  buttonText={item?.question}
                  clickFunc={() =>
                    clickFunc(item?.question, item?.contextName, item?.type)
                  }
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
export default PrePopulatedQus;
