import React, { createContext, useState, useContext } from "react";
import { API_BASE_LINK } from "./globalFunctions";

const ApiContext = createContext();

export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ children }) => {
  const [historyLoader, setHistoryLoader] = useState(false);
  const [history, setHistory] = useState([]);
  const [isStarred, setIsStarred] = useState(false);
  const userLoggedIn = localStorage?.getItem("email");

  const token = localStorage?.getItem("token");

  const getChatHistory = async (type, isStarred, date1, date2) => {
    const url = `${API_BASE_LINK}/history-api`;
    try {
      setHistoryLoader(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage?.getItem("token"),
        },
        body: JSON.stringify({
          username: localStorage?.getItem("email"),
          type: type && type !== undefined ? type : "API",
          star: isStarred,
          from_date: date1 !== null ? date1 : "",
          to_date: date2 !== null ? date2 : "",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const jsonResponse = await response.json();
      setHistory(jsonResponse?.chat_history);
      setHistoryLoader(false);
    } catch (error) {
      console.error("Error fetching API:", error);
    }
  };
  return (
    <ApiContext.Provider
      value={{
        token,
        history,
        setHistory,
        isStarred,
        setIsStarred,
        historyLoader,
        setHistoryLoader,
        getChatHistory,
        userLoggedIn,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
