import React from "react";
import { Link } from "react-router-dom";
import "./home.scss";

const HomePage = () => {
  return (
    <>
      <div className="home">
        <header className="p-3  border-bottom-0">
          <div className="container d-flex justify-content-between w-100">
            <Link to={"/chatbot"} className="invisible" target="_blank">
              <button aria-label="start" className="btn">
                Get Started
              </button>
            </Link>
            <Link to={"/chatbot"} target="_blank">
              <button aria-label="start" className="btn btn-light">
                Get Started
              </button>
            </Link>
          </div>
        </header>
        <main>
        <div className="text-center container centerlize w-100">
          <h1 className="hero-text mt-3">PHARMACOMPASS AI</h1>
          <h3 className="slogan text-white">Home Page of the Website</h3>
          <p className="sec-text text-white">
            Detailing out the information that can be searched on the site
          </p>
        </div>
        </main>
      </div>

      <footer className="pc-bg py-2">
        <ul className="d-flex gap-3 justify-content-center unstyled-list p-0 m-0">
          <li>
            <Link
              target="_blank"
              className="inherit-color text-decoration-none"
              to="https://www.pharmacompass.com/privacy-policy"
            >
              Privacy policy
            </Link>
          </li>
          <li>|</li>
          <li>
            <Link
              target="_blank"
              className="inherit-color text-decoration-none"
              to="https://www.pharmacompass.com/terms-and-conditions"
            >
              Terms and conditions
            </Link>
          </li>
          <li>|</li>
          <li>
            <Link
              target="_blank"
              className="inherit-color text-decoration-none"
              to="https://www.pharmacompass.com/disclaimers"
            >
              Disclaimers
            </Link>
          </li>
          <li>|</li>
          <li className="lastSocial">
            <a
              className="linkImg inherit-color text-decoration-none"
              target="_blank"
              href="https://in.linkedin.com/company/pharma-compass"
            >
              <img
                src="https://www.pharmacompass.com/image/linkedin_ln.png"
                alt="LinkedIn"
                height={20}
                width={20}
              />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
};
export default HomePage;
