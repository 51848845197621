import React from "react";

const AssistantLoader2 = () => {
  return (
    <div className="d-flex align-items-baseline justify-content-center mt-5">
      <span className="bouncing-loader ms-2">
        <div className="hw-10"></div>
        <div className="hw-10"></div>
        <div className="hw-10"></div>
      </span>
    </div>
  );
};
export default AssistantLoader2;
