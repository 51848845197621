import React, { useEffect, useState } from "react";
import "./modal.scss";
import { Closeicon } from "../SvgIcon";
import SubscriptionPlanList from "./SubscriptionPlanList";

// import Tooltip from "../Tooltip";
export const ConfirmationModal = ({ title, content, setClearHistory }) => {
  return (
    <>
      <div className="">
        <div className="modal-container">
          <div className="modal-outer">
            <div className="modal-head">
              <h2>{title}</h2>
            </div>
            <hr className="border-color" />
            <div className="modal-body">
              <p className="text-color mb-4" style={{ lineHeight: 1.6 }}>
                {content}
              </p>
              <div className="d-flex mt-2">
                <button
                  type="reset"
                  className="ms-auto modal-btn secandary-btn"
                  onClick={() => setClearHistory(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`ms-2 modal-btn primary-btn`}
                  onClick={() => setClearHistory(false)}
                >
                  Yes, delete chats
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="overlay-fill"
          onClick={() => setClearHistory(false)}
        ></div>
      </div>
    </>
  );
};

export const SubscriptionPlan = ({ setSubscriptionPlan }) => {
  const PlanView = ({ planName, amount, question }) => (
    <div className="active-subscription-plan active-subscription-plan-mini text-center">
      <h2 className="plan-name">{planName}</h2>
      <p className="amount gap-1">
        <p className="currency">$ </p> <span> {amount} </span>
        <p className="time">/month</p>
      </p>
      <h2 className="mini-question-left">{question} questions</h2>
      <button className="btn btn-theme w-100 hover-o">Select</button>
    </div>
  );
  return (
    <>
      <div className="modal-full-view">
        <div className="modal-full-view-inner theme-color">
          <div className="">
            <button
              aria-label="close"
              className="close-btn-x hover-change"
              onClick={() => setSubscriptionPlan(false)}
            >
              <Closeicon />
            </button>
          </div>
          <div className="subscriptions-plan">
            <h1 className="text-center mb-4 helvetica">Subscription Plans</h1>

            <div className="">
              <div className="d-flex flex-wrap gap-3">
                <PlanView planName="Basic" amount="00" question="12/150" />
                <PlanView
                  planName="Professtional"
                  amount="00"
                  question="12/150"
                />
                <PlanView planName="Business" amount="00" question="12/150" />
                <PlanView planName="Enterprise" amount="00" question="12/150" />
              </div>
              <div className="points-table border">
                <h1 className="text-center mb-4 helvetica">
                  How Pharmapoints Work
                </h1>
                <table className="w-100 ">
                  <thead>
                    <tr>
                      <th>Action Basic</th>
                      <th>Points</th>
                      <th>Frequency</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Basic Al Question</td>
                      <td>2 points</td>
                      <td>Once per question</td>
                    </tr>
                    <tr>
                      <td>Adding Industry Information</td>
                      <td>10 points</td>
                      <td>Once per info submission</td>
                    </tr>
                    <tr>
                      <td>Completing Weekly Quiz</td>
                      <td>15 points</td>
                      <td>Weekly</td>
                    </tr>
                    <tr>
                      <td>Signing Up for PharmaCompass</td>
                      <td>20 points</td>
                      <td>One-time</td>
                    </tr>
                    <tr>
                      <td>Achieving a 7-Day Streak</td>
                      <td>20 points</td>
                      <td>Weekly milestone</td>
                    </tr>
                    <tr>
                      <td>Referring New Users</td>
                      <td>25 points</td>
                      <td>Per referral</td>
                    </tr>
                    <tr>
                      <td>Company Registration</td>
                      <td>50 points</td>
                      <td>One-time</td>
                    </tr>
                    <tr>
                      <td>Referring New Companies</td>
                      <td>100 points</td>
                      <td>Per company referral</td>
                    </tr>
                    <tr>
                      <td>Achieving a 30-Day Streak</td>
                      <td>100 points</td>
                      <td>Monthly milestone</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          className="overlay-fill"
          // onClick={() => setClearHistory(false)}
        ></div>
      </div>
    </>
  );
};

export const Exhaustion = ({ planName }) => {
  const [subscriptionPlan, setSubscriptionPlan] = useState(false);
  const [activePlan, setActivePlan] = useState(false);

  const openPlanList = () => {
    setSubscriptionPlan(true);
    setActivePlan(true);
  };

  return (
    planName?.plan_status === false && (
      <>
        <div className="limit-exhaust py-4">
          <div className="mw-100 theme-color ">
            <div className="d-flex flex-column gap-3 text-center mx-auto">
              <h3 className="mb-0 msg">{planName?.plan_title}</h3>
              <p>{planName?.questions_allowed}</p>
              <button className="btn btn-orange mx-auto" onClick={openPlanList}>
                See Our Plans
              </button>
            </div>
          </div>
        </div>
        {subscriptionPlan && activePlan && (
          <SubscriptionPlanList
            setSubscriptionPlan={setSubscriptionPlan}
            setActivePlan={setActivePlan}
          />
        )}
      </>
    )
  );
};

export const FreePlanExhaust = () => {
  return (
    <div className="limit-exhaust py-4 d-none">
      <div className="mw-100 theme-color ">
        <div className="d-flex flex-column gap-3 text-center mx-auto">
          <h3 className="mb-0 msg">
            You have reached the limit of the Free Plan.
          </h3>
          <p>
            To continue enjoying uninterrupted service, please consider
            subscribing to one of our plans.
          </p>
          <button className="btn btn-orange mx-auto">See Our Plans</button>
        </div>
      </div>
    </div>
  );
};
