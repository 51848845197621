import React, { useEffect, useState } from "react";
import { useApi } from "../../../utils/ApiContext";
import parse from "html-react-parser";
import he from "he";

const Customization = ({
  setProfileModal,
  questionSection,
  setQuestionSection,
}) => {
  const [text0, setText0] = useState("");
  const [text1, setText1] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  const { userLoggedIn } = useApi();

  const handleChange = (text, id) => {
    if (id === "1") {
      setText0(text);
    } else {
      setText1(text);
    }
    setIsChanged(true);
  };

  const handleCustomiseModel2 = (text0, text1) => {
    fetch(`https://www.pharmacompass.com/customize_response`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: localStorage?.getItem("token"),
      },

      body: JSON.stringify({
        username: userLoggedIn,
        questions: [
          {
            question_id: "1",
            customize_response: text0 ? text0 : "",
          },
          {
            question_id: "2",
            customize_response: text1 ? text1 : "",
          },
        ],
      }),
    })
      .then((Response) => Response.json())
      .then((data) => setQuestionSection(data));
  };

  const submitCustomization = () => {
    handleCustomiseModel2(text0, text1);
    setProfileModal(false);
  };

  useEffect(() => {
    if (questionSection.length > 0) {
      const question1 = questionSection?.find((q) => q?.question_id === "1");
      const question2 = questionSection?.find((q) => q?.question_id === "2");
      setText0(question1?.customize_response || "");
      setText1(question2?.customize_response || "");
    }
  }, [questionSection]);

  return (
    <>
      <h2 className="mb-4">Customize PharmaPoint</h2>
      <form action="">
        <div className="internal-content">
          {questionSection?.map((data, index) => {
            const decodedHtml = he.decode(data?.examples);
            return (
              <div key={index}>
                <h3 className="mb-2">{data?.question}</h3>
                <div className="d-flex align-items-start gap-3 gap-4">
                  <div className="textarea-box">
                    <textarea
                      rows={10}
                      id={data?.question_id}
                      className="modal-textarea w-100"
                      value={data?.question_id === "1" ? text0 : text1}
                      onChange={(e) =>
                        handleChange(e.target.value, data?.question_id)
                      }
                      cols="50"
                    />
                  </div>
                  {parse(decodedHtml)}
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex mt-2">
          <button
            type="reset"
            className="ms-auto btn btn-outlined-theme"
            onClick={() => setProfileModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`ms-2 btn btn-orange ${!isChanged ? "isDisabled" : ""}`}
            onClick={submitCustomization}
            disabled={!isChanged ? true : false}
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};
export default Customization;
