import React from "react";
import DefaultImg from "../../../assets/imgs/undefined.jpg";
import { Link } from "react-router-dom";

export const UserProfile = ({ userInfo }) => {
  return (
    <>
      <h2 className="mb-4">Personal Profile</h2>
      <div className="profile-details read-only d-flex align-items-start gap-4">
        <Link to="#">
          <img
            src={
              userInfo?.image !== undefined
                ? `https://www.pharmacompass.com/image/profile/${userInfo?.image}`
                : DefaultImg
            }
            alt={`${userInfo?.fname}-profile-picture`}
            height={200}
            width={200}
            className={`profile-picture theme-bg ${
              userInfo?.image === undefined && "border"
            }`}
            loading="lazy"
          />
        </Link>
        <div className="fill-width">
          <div className="d-flex flex-column">
            <div className="d-flex mb-3 align-items-center">
              <label htmlFor="userInfocompany">Company name</label>
              <input
                disabled
                type="text"
                id="userInfocompany"
                value={userInfo?.company}
              />
            </div>
            <div className="d-flex mb-3 align-items-center">
              <label htmlFor="userInfotitle">Title</label>
              <input
                disabled
                type="text"
                id="userInfotitle"
                value={userInfo?.title}
              />
            </div>
            <div className="d-flex mb-3 align-items-center">
              <label htmlFor="userInfofname">First Name</label>
              <input
                disabled
                type="text"
                id="userInfofname"
                value={userInfo?.fname}
              />
            </div>
            <div className="d-flex mb-3 align-items-center">
              <label htmlFor="userInfolname">Last Name</label>
              <input
                disabled
                type="text"
                id="userInfolname"
                value={userInfo?.lname}
              />
            </div>
            <div className="d-flex mb-3 align-items-center">
              <label htmlFor="userInfogender">Gender</label>
              <input
                disabled
                type="text"
                id="userInfogender"
                value={userInfo?.gender}
              />
            </div>
            <div className="d-flex mb-3 align-items-center">
              <label htmlFor="userInfocontact">
                District Line or Mobile Number
              </label>
              <input
                disabled
                type="text"
                id="userInfocontact"
                value={userInfo?.contact}
              />
            </div>
            <div className="d-flex mb-3 align-items-center">
              <label htmlFor="userInfoemail">Email Address</label>
              <input
                disabled
                type="text"
                id="userInfoemail"
                value={userInfo?.email}
              />
            </div>
            <div className="d-flex mb-3 align-items-center">
              <label htmlFor="">Password</label>
              <input disabled type="text" name="" id="" value={`***********`} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
