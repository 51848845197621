import React, { useState, useEffect } from "react";
import "./layout.scss";
import { PC_API_LINK } from "../utils/globalFunctions";
import Login from "./Auth/Login";
import { Header, Header2 } from "./Header";
import QusAns from "../chat-area/QusAns";
import SingleAd from "./SingleAd";
import { WithoutLoginBlock } from "./SideMenu";
import { useApi } from "../utils/ApiContext";
import { getActivePlan } from "../utils/EndPoint";

const Layout = () => {
  const [screenLoader, setScreenLoader] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuLoaded, setIsMenuLoaded] = useState(false);

  const [clickedId, setClickedId] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [chatData, setChatData] = useState([]);

  const [contextList, setContextList] = useState();
  const [contextIndex, setContextIndex] = useState(0);
  const [qusContext, setQusContext] = useState("");
  const [contextType, setContextType] = useState("API");
  const [placeholderText, setPlaceholderText] =
    useState(`Ask PharmaCompass.AI`);
  const { getChatHistory, userLoggedIn } = useApi();

  const loginCheck = () => {
    !localStorage?.getItem("email")
      ? setIsLoggedIn(true)
      : setIsLoggedIn(false);
  };
  
  const getSessionData = () => {
    const userData = localStorage.getItem("user");
    const expiration = localStorage.getItem("expiration");
    const now = new Date().getTime();
    if (expiration && now > expiration) {
      localStorage.removeItem("user");
      localStorage.removeItem("expiration");
      localStorage.removeItem("sessionId");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("client");
      return null;
    }
    return userData ? JSON.parse(userData) : null;
  };

  useEffect(() => {
    getSessionData();
    if (userLoggedIn) {
      getChatHistory(contextType, false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchData = async () => {
      setScreenLoader(true);
      setIsMenuLoaded(false);
      try {
        const response = await fetch(`${PC_API_LINK}/get_left_menu/`, {
          headers: { Authorization: localStorage?.getItem("token") },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response?.status}`);
        }
        const data = await response.json();
        setContextList(data);
        setIsMenuLoaded(true);
        setScreenLoader(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    getChatHistory(contextType, false);
  }, [contextIndex]);



  useEffect(() => {
    getActivePlan(userLoggedIn);
  }, []);



  return (
    <>
      {screenLoader && (
        <div className="pageLoader loader-active">
          <img
            src="./logo192.png"
            alt="logo"
            loading="eager"
            height={120}
            className="pulse"
          />
        </div>
      )}
      <main className="main">
        <Header
          setIsLoggedIn={setIsLoggedIn}
          setChatData={setChatData}
          setClickedId={setClickedId}
        />
        <Header2 />
        <div className="ai-pc d-flex px-0 w-100">
          <aside className="ai-query-history px-xl-3 px-lg-2 px-md-2 px-sm-2">
            <div className="side-menu-link d-flex flex-column h-100 position-relative">
              <h3 className="mb-2">Category</h3>
              <div className="use-context">
                {isMenuLoaded === false ? (
                  <>
                    <ul className="unstyled-list ps-0 m-0 menu-skeleton-grid d-flex flex-column">
                      <li className="menu-skeleton"></li>
                      <li className="menu-skeleton"></li>
                      <li className="menu-skeleton"></li>
                      <li className="menu-skeleton"></li>
                    </ul>
                  </>
                ) : (
                  contextList?.contextList?.map((item, index) => {
                    const loggedInEmail = localStorage.getItem("email");
                    const isAccessDenied =
                      (!item?.accessForOthers &&
                        loggedInEmail !== "tarun@alcheminternational.com") ||
                      (loggedInEmail === "tarun@alcheminternational.com" &&
                        !item?.accessFortarun);

                    const buttonClass = `${
                      index === contextIndex ? "active" : ""
                    } ${isAccessDenied ? "coming-soon" : ""}`;
                    return (
                      <button
                        key={index}
                        type="text"
                        aria-label={item?.contextDisplayName + "-button"}
                        className={buttonClass}
                        disabled={isAccessDenied}
                        onClick={() => {
                          loginCheck();
                          setContextIndex(index);
                          setQusContext(item?.contextName);
                          setContextType(item?.type);
                          setChatData([]);
                          setClickedId("");
                          setPlaceholderText("Ask PharmaCompass.AI");
                        }}
                      >
                        {item?.contextDisplayName}
                      </button>
                    );
                  })
                )}
              </div>

              <div className="search-history mt-auto mb-2p7">
                <WithoutLoginBlock />
              </div>
            </div>
          </aside>
          <div className="ai-responses-area border-start-0 border-end-0 ">
            <QusAns
              chatData={chatData}
              clickedId={clickedId}
              qusContext={qusContext}
              contextType={contextType}
              setActiveIndex={setActiveIndex}
              setChatData={setChatData}
              setClickedId={setClickedId}
              placeholderText={placeholderText}
              setPlaceholderText={setPlaceholderText}
            />
          </div>
          <SingleAd />
        </div>
      </main>
      {isLoggedIn && (
        <Login
          setIsLoggedIn={setIsLoggedIn}
          setScreenLoader={setScreenLoader}
        />
      )}
    </>
  );
};
export default Layout;
