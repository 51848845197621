import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { FaCircleUser } from "react-icons/fa6";
// import Tooltip from "./ui-component/Tooltip";
import { ArrowDown, ArrowDown2 } from "./ui-component/SvgIcon";
import { ConfirmationModal } from "./ui-component/popups/Modals";
import ProfileTab from "./ui-component/popups/ProfileTab";
import SubscriptionPlanList from "./ui-component/popups/SubscriptionPlanList";
import { ActiveSubscriptionPlan } from "./ui-component/popups/ActiveSubscriptionPlan";
import { getActivePlan } from "../utils/EndPoint";
import { useApi } from "../utils/ApiContext";

export const Header = ({ setIsLoggedIn, setChatData, setClickedId }) => {
  const [clearHistory, setClearHistory] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(false);
  const [activePlan, setActivePlan] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [activePlanDetail, setActivePlanDetail] = useState([]);
  const dropDownRef = useRef(null);
  const { userLoggedIn } = useApi();

  const LogoutSession = () => {
    setDropDown(false);
    localStorage.removeItem("sessionId");
    localStorage.removeItem("email");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("client");
    localStorage.setItem("logout", Date.now());
    window.location.reload();
  };

  const MyProfile = () => {
    setProfileModal(true);
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event?.key === "logout") {
        window.location.href = "/chatbot";
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropDownRef?.current &&
        !dropDownRef?.current?.contains(event?.target)
      ) {
        setDropDown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchUserActivePlan = async () => {
    try {
      const activePlanData = await getActivePlan(userLoggedIn,localStorage?.getItem("token"));
      setActivePlanDetail(activePlanData);
      if (activePlanData?.length > 0 && activePlanData[0]?.plan_id === null) {
        setSubscriptionPlan(true);
        return;
      } else if (
        activePlanData?.length > 0 &&
        activePlanData[0]?.plan_id !== null
      ) {
        setActivePlan(true);
      } else {
        setActivePlan(false);
        setSubscriptionPlan(true);
      }
    } catch (error) {
      console.error("Error fetching active plan:", error);
      setActivePlan(false);
      setSubscriptionPlan(true);
    }
  };


  // const fetchUserActivePlan = async () => {
  //   const CurrentPlans = sessionStorage.getItem("currentPlans");
  //   if (CurrentPlans) {
  //     setActivePlanDetail(JSON.parse(CurrentPlans));
  //   } else {
  //     try {
  //       const activePlanData = await getActivePlan(
  //         userLoggedIn,
  //         localStorage?.getItem("token")
  //       );
  //       sessionStorage.setItem("currentPlans", JSON.stringify(activePlanData));
  //       setActivePlanDetail(activePlanData);
  //       if (activePlanData?.length > 0 && activePlanData[0]?.plan_id === null) {
  //         setSubscriptionPlan(true);
  //         return;
  //       } else if (
  //         activePlanData?.length > 0 &&
  //         activePlanData[0]?.plan_id !== null
  //       ) {
  //         setActivePlan(true);
  //       } else {
  //         setActivePlan(false);
  //         setSubscriptionPlan(true);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching active plan:", error);
  //       setActivePlan(false);
  //       setSubscriptionPlan(true);
  //     }
  //   }
  // };


  return (
    <>
      <header className="border-bottom-0 px-0 py-2">
        <div className="header-1 d-flex align-items-center justify-content-between">
          <img
            src="https://www.pharmacompass.com/dev/image/vb/pharmacompass-white.png?v=4"
            alt="logo"
            height={52}
          />
          <div className="d-flex">
            <Link to="#" className="text-decoration-none mx-3">
              {localStorage.getItem("email") ? (
                <div className="btn-group position-relative">
                  <button
                    className="btn d-flex gap-2 px-2 auth-btn"
                    aria-label="username"
                  >
                    <span
                      className="d-block text-truncate"
                      style={{ width: 80 }}
                    >
                      {localStorage?.getItem("firstName")
                        ? localStorage?.getItem("firstName")
                        : `User`}
                    </span>
                  </button>
                  <button
                    aria-label="logout"
                    className="btn d-flex gap-2 px-2 align-items-center auth-btn"
                    onClick={() => setDropDown(!dropDown)}
                  >
                    <ArrowDown2 color={"var(--white)"} />
                  </button>
                  {dropDown && (
                    <div className="btn-dropdown" ref={dropDownRef}>
                      <ul className="m-0 p-0 unstyled-list d-flex flex-column gap-0">
                        <li className="d-flex gap-2" onClick={LogoutSession}>
                          <span>
                            <MdLogout fontSize={20} />
                          </span>
                          Logout
                        </li>
                        <li className="d-flex gap-2" onClick={MyProfile}>
                          <span>
                            <FaCircleUser fontSize={20} />
                          </span>
                          Personal Profile
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              ) : (
                <button
                  aria-label="Login"
                  className="btn auth-btn"
                  onClick={() => {
                    setIsLoggedIn(true);
                  }}
                >
                  Login
                </button>
              )}
            </Link>
            <button
              aria-label="pc coins"
              // className="btn btn-outlined isDisabled"
              // disabled
              className="btn btn-outlined"
              // onClick={() => setActivePlan(true)}
              onClick={fetchUserActivePlan}
            >
              PharmaPoints
            </button>
          </div>
        </div>
      </header>
      {clearHistory && (
        <ConfirmationModal
          title={"Delete all chats?"}
          content={
            <>
              Are you sure you want to delete all chats?
              <br />
              Deleting all chats will clear all the searched history till date.
            </>
          }
          setClearHistory={setClearHistory}
        />
      )}
      {subscriptionPlan && (
        <SubscriptionPlanList
          setSubscriptionPlan={setSubscriptionPlan}
          setActivePlan={setActivePlan}
        />
      )}
      {activePlan && (
        <ActiveSubscriptionPlan
          activePlanDetail={activePlanDetail}
          activePlan={activePlan}
          setActivePlan={setActivePlan}
          setSubscriptionPlan={setSubscriptionPlan}
        />
      )}
      {profileModal && <ProfileTab setProfileModal={setProfileModal} />}
    </>
  );
};

export const Header2 = ({ setIsLoggedIn, setChatData, setClickedId }) => {
  const [menuServiceHtml, setServiceMenuHtml] = useState("");
  const [expMenuHtml, setExpMenuHtml] = useState("");
  const [pipMenuHtml, setPipMenuHtmlMenuHtml] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const menuRef = useRef(null);
  useEffect(() => {
    const handleHover = (event) => {
      const liElement = event.currentTarget;
      const subMenu = liElement.querySelector("ul");

      if (subMenu) {
        const yOffset =
          parseFloat(liElement.getBoundingClientRect().top) + 32 + "px";
        subMenu.style.top = yOffset;

        const checkHeightUL = parseFloat(subMenu?.offsetHeight) + 5;
        if (checkHeightUL > 299) {
          subMenu.style.bottom = "0px";
        } else {
          subMenu.style.bottom = "auto";
        }
      }
    };

    const topLevelItems = document.querySelectorAll(
      ".Dis-mob > ul > div > li "
    );
    const secondLevelItems = document.querySelectorAll(
      ".Dis-mob > ul > div > li > ul > li"
    );

    topLevelItems.forEach((item) => {
      item.addEventListener("mouseenter", handleHover);
      item.addEventListener("mouseleave", () => {
        const subMenu = item.querySelector("ul");
        if (subMenu) subMenu.style.bottom = "auto";
      });
    });

    secondLevelItems.forEach((item) => {
      item.addEventListener("mouseenter", handleHover);
      item.addEventListener("mouseleave", () => {
        const subMenu = item.querySelector("ul");
        if (subMenu) subMenu.style.bottom = "auto";
      });
    });

    return () => {
      topLevelItems.forEach((item) => {
        item.removeEventListener("mouseenter", handleHover);
        item.removeEventListener("mouseleave", handleHover);
      });
      secondLevelItems.forEach((item) => {
        item.removeEventListener("mouseenter", handleHover);
        item.removeEventListener("mouseleave", handleHover);
      });
    };
  });
  useEffect(() => {
    fetch("/menu.txt")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        setServiceMenuHtml(data);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  }, []);
  useEffect(() => {
    fetch("/pipeline-prospector.txt")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        setPipMenuHtmlMenuHtml(data);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  }, []);
  useEffect(() => {
    fetch("/menu-excipients.txt")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        setExpMenuHtml(data);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  }, []);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveIndex(null); // Close the menu if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const showHideMenu = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <header className="mb-xl-4 mb-lg-3 mb-md-3 bg-auto" ref={menuRef}>
        <div className="header-1 header-2 d-flex align-items-center">
          <div className="menuInnerHeader">
            <ul className="d-flex p-0 m-0 unstyled-list gap-3">
              <li
                className={`face-menu Dis-mob single-menu-ul ${
                  activeIndex === 1 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(1)}
              >
                Find Suppliers : API/FDF <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list Dis-mob FS-Disable single-menu">
                  <li className="first-kind">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/find-suppliers"
                      target="_blank"
                    >
                      Search Our Integrated Database
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={`face-menu Dis-mob ${
                  activeIndex === 2 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(2)}
              >
                Services <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list">
                  <div dangerouslySetInnerHTML={{ __html: menuServiceHtml }} />
                </ul>
              </li>
              <li
                className={`face-menu Dis-mob ${
                  activeIndex === 3 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(3)}
              >
                Excipients <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list hide">
                  <div dangerouslySetInnerHTML={{ __html: expMenuHtml }} />
                </ul>
              </li>
              <li
                className={`face-menu Dis-mob ${
                  activeIndex === 4 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(4)}
              >
                Drugs in Dev. <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list hide">
                  <div dangerouslySetInnerHTML={{ __html: pipMenuHtml }} />
                </ul>
              </li>
              <li
                className={`face-menu Dis-mob single-menu-ul ${
                  activeIndex === 5 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(5)}
              >
                News <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list Dis-mob FS-Disable single-menu">
                  <li className="first-kind">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/pharma-and-biotech-news"
                      target="_blank"
                    >
                      Pharma &amp; Biotech News #PharmaBuzz
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/weekly-news-recap-phispers"
                      target="_blank"
                    >
                      Weekly News Recap #Phispers
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/recent-products-approval-greenlight"
                      target="_blank"
                    >
                      Recent Product Approvals #GreenLight
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/quality-compliance-alert-warning"
                      target="_blank"
                    >
                      Quality Compliance Alerts #Warning!
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/latest-news-whats-happening"
                      target="_blank"
                    >
                      All News #Library
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={`face-menu Dis-mob single-menu-ul ${
                  activeIndex === 6 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(6)}
              >
                Blog <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list Dis-mob FS-Disable single-menu">
                  <li className="first-kind">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/blog"
                      target="_blank"
                    >
                      All Content by PharmaCompass &amp; Suppliers
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/radio-compass-blog"
                      target="_blank"
                    >
                      Data Compilation #PharmaFlow
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/pipeline-prospector-blog"
                      target="_blank"
                    >
                      Stock Recap #PipelineProspector
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/speak-pharma"
                      target="_blank"
                    >
                      Interview #SpeakPharma
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/video-supplier-spotlight"
                      target="_blank"
                    >
                      Video #SupplierSpotlight
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/video-pharmareel"
                      target="_blank"
                    >
                      Vlog #PharmaReel
                    </a>
                  </li>
                  <li className="">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/supplier-blogs"
                      target="_blank"
                    >
                      Company Bio #AboutSupplier
                    </a>
                  </li>
                  <li className="showdropmenu">
                    <a
                      rel="noreferrer"
                      href="https://www.pharmacompass.com/pharma-blog"
                      target="_blank"
                    >
                      Service Bio #AboutCapabilities
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};
