import React from "react";
import { Closeicon } from "./SvgIcon";
import { SupportImg } from "../../utils/imgPaths";

export const AlertPopup = ({
  title = "",
  content = "",
  setAlertPopup,
  submitFunction = setAlertPopup(true),
  buttonName = "Ok, got it!",
  isDisabled,
  hasTable,
  setSelectedRows,
}) => {
  return (
    <>
      <div className="alert-container border shadow-lg">
        <div className="position-relative">
          <button
            aria-label="close"
            type="reset"
            className="ml-end bg-transparent border-0 cursor-pointer position-absolute end_10 top_10 hover-change"
            onClick={() => {
              setAlertPopup(false);
              setSelectedRows([]);
            }}
          >
            <Closeicon />
          </button>
          <div className="d-flex flex-column gap-3 res-text bg-transparent border-0 theme-color">
            <h2 className="title mb-0 lh-1">{title}</h2>
            <div className="content mt-3 mb-1">{content}</div>
            <button
              aria-label="btn name"
              type="button"
              className={`btn btn-theme mw-160 ${
                hasTable === true && isDisabled?.length === 0
                  ? "isDisabled"
                  : ""
              }`}
              onClick={
                hasTable === true && isDisabled?.length === 0
                  ? undefined
                  : submitFunction
              }
              disabled={
                hasTable === true && isDisabled?.length === 0 ? true : false
              }
            >
              {buttonName}
            </button>
          </div>
        </div>
      </div>
      <div className="overlay-fill" onClick={() => setAlertPopup(false)}></div>
    </>
  );
};

export const AlertPopup2 = ({
  title = "",
  content = "",
  setAlertPopup,
  submitFunction = setAlertPopup(true),
  buttonName = "Ok, got it!",
  hasBg=false
}) => {
  return (
    <>
      <div className="alert-container alert-container-2 border shadow-lg">
        <button
              aria-label="close"
              className="close-btn-x hover-change"
              onClick={() => setAlertPopup(false)}
              style={{zIndex:1}}
            >
              <Closeicon />
            </button>
        <div className="position-relative">
          <div className="d-flex flex-column gap-3 res-text bg-transparent border-0 theme-color">
            {!hasBg?
            <div className="mail-sent mt-3"></div>
            :
            <img src={SupportImg} alt="" className="mx-auto"  height={120} width={120}/>
            }
            <div className="content mt-2 mb-1 text-center">
              {title}
              {content}
            </div>
            <button
              aria-label="btn name"
              type="button"
              className="btn mx-auto btn-theme"
              onClick={submitFunction}
            >
              {buttonName}
            </button>
          </div>
        </div>
      </div>
      <div className="overlay-fill" onClick={() => setAlertPopup(false)}></div>
    </>
  );
};
