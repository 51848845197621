import React, { useState } from "react";
import "./auth.scss";
import { At, EyeOff, EyeOn, Lock } from "../../utils/imgPaths";
import { Link } from "react-router-dom";
import { API_BASE_LINK, PC_LINK } from "../../utils/globalFunctions";
import { Closeicon } from "../ui-component/SvgIcon";
import parse from "html-react-parser";

const Login = ({ setIsLoggedIn, setScreenLoader }) => {
  const [eye, setEye] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      // "https://www.pharmacompass.com/app/1/9/form/login/",
      // ${API_BASE_LINK}/login
      const response = await fetch(`${API_BASE_LINK}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: JSON.stringify({
          email: email,
          password: password,
          token: "YWxhZGRpbjpvcGVuc2VzYW1l|android",
          agree: true,
        }),
      });

      const data = await response.json();
      if (data?.status === 0) {
        setError(true);
        const cleanMessage = parse(data?.message)
          // .replace(/<br>/g, " ")
          // .replace(/<\/?span[^>]*>/g, "")
          // .replace(/<\/?a[^>]*>/g, "")
          // .replace(/<\/?[^>]+(>|$)/g, "");
        setErrorMsg(cleanMessage);
        setIsLoggedIn(true);
      } else {
        setError(false);
        setIsLoggedIn(false);
        localStorage.setItem("sessionId", data?.session?.id);
        localStorage.setItem("email", data?.session?.email);
        localStorage.setItem("firstName", data?.session?.fname);
        localStorage.setItem("lastName", data?.session?.lname);
        localStorage.setItem("client", data?.session?.client);
        localStorage.setItem("token", data?.session?.token);

        const userData = localStorage.getItem("email");
        const now = new Date().getTime();
        const expirationTime = now + 7 * 24 * 60 * 60 * 1000;
        // const expirationTime = now + 30 * 1000;
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("expiration", expirationTime);
        window.location.reload();
        setScreenLoader(true);
      }

      if (response.ok) {
      } else {
        setError(data.message || "Login failed");
      }
    } catch (error) {
      setError("An error occurred while logging in.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="form-position shadow-lg">
        <button
          aria-label="close"
          className="close-btn-x hover-change"
          onClick={() => setIsLoggedIn(false)}
        >
          <Closeicon />
        </button>
        <form className="form login-form" onSubmit={handleLogin}>
          <h2 className="mb-4 theme-color">Login</h2>
          {error && (
            <p className="error-l text-danger">
              {errorMsg !== "" ? (
                errorMsg
              ) : (
                <>Something went wrong, please try again.</>
              )}
            </p>
          )}
          <div className="flex-column">
            <label htmlFor="email">Email </label>
          </div>
          <div className="inputForm mb-2">
            <img src={At} alt="AtIcon" className="" height={20} width={20} />
            <input
              type="email"
              id="email"
              className="input"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              title=""
              required
            />
          </div>

          <div className="flex-column">
            <label htmlFor="password">Password </label>
          </div>
          <div className="inputForm">
            <img src={Lock} alt="Lock" className="" height={20} width={20} />
            <input
              type={eye === true ? "password" : "text"}
              id="password"
              className="input"
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              title=""
              required
            />
            <img
              src={eye === true ? EyeOff : EyeOn}
              alt="eye-on-off-icon"
              className="me-2 cursor-pointer"
              height={20}
              width={20}
              onClick={() => setEye(!eye)}
            />
          </div>

          <div className="flex-row justify-content-end">
            <Link
              className="text-decoration-none"
              target="_blank"
              to={`${PC_LINK}forgot-password`}
            >
              <span className="span">Forgot password?</span>
            </Link>
          </div>

          <div
            className={`position-relative mt-3 mb-2 ${loading ? "" : "glare"}`}
          >
            <button
              aria-label="submit"
              type="submit"
              className="button-submit"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>

          <p className="p">
            Don't have an account?
            <Link
              className="text-decoration-none"
              target="_blank"
              to={`${PC_LINK}create-an-account`}
            >
              <span className="span">Sign Up</span>
            </Link>
          </p>
        </form>
      </div>
      <div className="overlay-fill"></div>
    </>
  );
};

export default Login;
