import React from "react";
import { OutlinedLeftButton } from "../../components/ui-component/buttons/ButtonType";
import { Para } from "../../components/ui-component/SvgIcon";

const REALTED_QUESTION = [
  {
    qestion: "USDMF Suppliers of Semaglutide?",
    contextName: "chat",
    type: "API",
  },
  {
    qestion: "Latest news on Sanofi",
    contextName: "chat483",
    type: "483",
  },
  {
    qestion: "API price of Thiocolchicoside to Turkey in 2024?",
    contextName: "TRADE",
    type: "",
  }
];

export const RelatedQuestion = () => {
  return (
    <>
      <div className="ps-45">
        <p className="pre-populated-related-question border-top mb-3 pt-4 d-flex align-items-center"><Para/> Related questions</p>
        <div className="pre-populated-question pre-populated-related-question">
          {REALTED_QUESTION?.map((item, index) => {
            return <OutlinedLeftButton key={index} buttonText={item?.qestion} />;
          })}
        </div>
      </div>
    </>
  );
};
