import "./App.css";
import Layout from "./components/Layout";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import HomePage from "./home/HomePage";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ApiProvider } from "./utils/ApiContext";

function App() {
  const ChangeBodyColor = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === "/") {
        document.body.style.backgroundColor = "#000c3b";
      } else {
        document.body.style.backgroundColor = "#000c3b";
      }

      return () => {
        document.body.style.backgroundColor = "";
      };
    }, [location]);

    return null;
  };
  return (
    <>
    <ApiProvider>
      <Router>
        <ChangeBodyColor />
        <Routes>
          <Route path="/" exact element={<HomePage bgColor={"var(--pcBlue)"} />} />
          <Route
            path="/chatbot"
            exact
            element={<Layout bgColor={"var(--pcBlue)"} />}
          />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Router>
      </ApiProvider>
    </>
  );
}

export default App;
