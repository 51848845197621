import React, { useEffect, useState } from "react";
import { UserProfile } from "./UserProfile";
import Customization from "./Customization";
import { Closeicon } from "../SvgIcon";
import { fetchProfile, handleCustomiseModel } from "../../../utils/EndPoint";
import { useApi } from "../../../utils/ApiContext";

const ProfileTab = ({ setProfileModal }) => {
  const [activeIndex, setActiveIndex] = useState("0");
  const [tabView, setTabView] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [questionSection, setQuestionSection] = useState([]);
  const { userLoggedIn } = useApi();

  useEffect(() => {
    setActiveIndex("1");
    setTabView(true);
  }, []);

  const fetchUserProfile = async () => {
    try {
      const profile = await fetchProfile(localStorage?.getItem("token"));
      setUserInfo(profile);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCustomize = async () => {
    try {
      const data = await handleCustomiseModel(userLoggedIn,localStorage?.getItem("token"));
      setQuestionSection(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserProfile();
    fetchCustomize();
  }, []);

  return (
    <>
      <div className="mb-3">
        <div className="modal-container">
          <div className="">
            <button
              aria-label="close"
              className="close-btn-x hover-change"
              onClick={() => setProfileModal(false)}
            >
              <Closeicon />
            </button>
          </div>
          <div className="modal-outer modal-lg">
            <div className="modal-head">
              <div className="tab-btn-bar">
                <div className="btn-group-o">
                  <button
                    className={`btn me-3 ${
                      activeIndex === "1" ? "btn-orange" : "btn-outlined-o"
                    }`}
                    onClick={() => setActiveIndex("1")}
                  >
                    Personal Information
                  </button>
                  <button
                    onClick={() => setActiveIndex("2")}
                    className={`btn ${
                      activeIndex === "2" ? "btn-orange" : "btn-outlined-o"
                    }`}
                  >
                    Customize PC.AI
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="tab-btn-content">
                {tabView && (
                  <>
                    {activeIndex === "1" && <UserProfile userInfo={userInfo} />}
                    {activeIndex === "2" && (
                      <Customization
                        setProfileModal={setProfileModal}
                        questionSection={questionSection}
                        setQuestionSection={setQuestionSection}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay-fill"></div>
    </>
  );
};

export default ProfileTab;
