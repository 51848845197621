import React, { useEffect, useState } from "react";
import { API_BASE_LINK } from "../utils/globalFunctions";
import {
  Closeicon,
  HistoryIcon,
  NewChatIcon,
  NoResults,
} from "../components/ui-component/SvgIcon";
import { FaCircleCheck } from "react-icons/fa6";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { FaCalendarAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { CgRadioCheck } from "react-icons/cg";
import DatePicker from "react-datepicker";
import { useApi } from "../utils/ApiContext";

const SearchHistory = ({
  chatData,
  setHistory,
  setChatData,
  setClickedId,
  func,
  setPlaceholderText,
  contextType,
}) => {
  const [fetchHistory, setFetchHistory] = useState(false);
  const [selectedChats, setSelectedChats] = useState([]);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [formattedSelectedDate1, setFormattedSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [formattedSelectedDate2, setFormattedSelectedDate2] = useState(null);

  const { history, getChatHistory, userLoggedIn } = useApi();
  
  const toggleCheckbox = (chatId) => {
    setSelectedChats((prevState) => {
      const isSelected = prevState.includes(chatId);
      if (isSelected) {
        return prevState.filter((id) => id !== chatId);
      } else {
        return [...prevState, chatId];
      }
    });
  };

  const deleteSelectedChats = () => {
    if (selectedChats?.length > 0) {
      fetch(`${API_BASE_LINK}/history-delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage?.getItem("token"),
        },
        body: JSON.stringify({
          username: userLoggedIn,
          type: contextType,
          is_delete: true,
          chat_ids: selectedChats, // Pass the array directly
        }),
      })
        .then((response) => response.json())
        .then(() => {
          setSelectedChats([]); // Clear the selected chats
          getChatHistory(contextType, false); // Refresh chat history
        })
        .catch((error) => console.error("Error deleting chats:", error));
    } else {
      console.log("No chats selected for deletion.");
    }
  };

  const historyChat = async (chatId, index, type) => {
    if (chatId !== undefined) {
      setClickedId(chatId);
      // const url = `${PC_API_LINK}/getchatdetails/`;
      const url = `${API_BASE_LINK}/getchatdetails`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage?.getItem("token"),
          },
          body: JSON.stringify({
            chat_id: chatId,
            type: type && type !== undefined ? type : "API",
          }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonResponse = await response.json();
        setChatData(jsonResponse);
        setFetchHistory(false);
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    }
  };
  const Resetchat = () => {
    setFetchHistory(false);
    setChatData([]);
    setClickedId("");
    func();
    setPlaceholderText("Ask PharmaCompass.AI");
  };
  const disableDP1 = (date) => {
    const specifiedDate = new Date("2024-10-22");
    const today = new Date();
    today?.setHours(0, 0, 0, 0);
    return date >= specifiedDate && date <= today;
  };
  const disableDP2 = (date) => {
    // if (!selectedDate1) return false;
    const today = new Date();
    today?.setHours(0, 0, 0, 0);
    return date >= selectedDate1 && date <= today;
  };
  const ShowStarredData = () => {
    getChatHistory(contextType, true);
  };
  const HandleHistory = () => {
    if (userLoggedIn) {
      setFetchHistory(!fetchHistory);
      getChatHistory(contextType, false);
    }
  };
  useEffect(() => {
    getChatHistory(
      contextType,
      false,
      formattedSelectedDate1,
      formattedSelectedDate2
    );
  }, [selectedDate1, selectedDate2]);

  return (
    <>
      <div className="d-flex mx-auto align-items-center">
        <div
          className={`d-flex align-items-center me-3 ${
            !userLoggedIn ? "" : "cursor-pointer"
          } gap-1`}
          onClick={HandleHistory}
          style={{ cursor: !userLoggedIn ? "no-drop" : "pointer" }}
        >
          <HistoryIcon />
          <span className="theme-color fs-14 m-0 p-0">History</span>
        </div>
        <div
          className={`d-flex align-items-center me-3 ${
            !userLoggedIn ? "" : "cursor-pointer"
          } gap-1`}
          onClick={Resetchat}
          style={{ cursor: !userLoggedIn ? "no-drop" : "pointer" }}
        >
          <NewChatIcon />
          <span className="theme-color fs-14 m-0 p-0 nowrap"> New Chat</span>
        </div>
      </div>
      {fetchHistory && fetchHistory?.length !== 0 ? (
        <>
          <div className="related-search-result">
            <h2 className={`mb-3`}>Search History</h2>
            <button
              aria-label="close"
              className="close-btn-x hover-change"
              onClick={() => setFetchHistory(false)}
            >
              <Closeicon />
            </button>
            <div className="d-flex justify-content-between align-items-center w-95 mb-2">
              <div className="dates-selection d-flex">
                <DatePicker
                  showIcon
                  placeholderText="From"
                  calendarIconClassName="position-absolute end-0 top-0"
                  icon={<FaCalendarAlt color="var(--pcBlue)" />}
                  closeOnScroll={(e) => e.target === document}
                  dateFormat={"dd/MM/YYYY"}
                  selected={selectedDate1}
                  onChange={(date) => {
                    setSelectedDate1(date);
                    setFormattedSelectedDate1(date.toLocaleDateString("en-GB"));
                    setSelectedDate2(null);
                  }}
                  filterDate={disableDP1}
                />
                <DatePicker
                  showIcon
                  placeholderText="To"
                  calendarIconClassName="position-absolute end-0 top-0"
                  icon={<FaCalendarAlt color="var(--pcBlue)" />}
                  closeOnScroll={(e) => e.target === document}
                  dateFormat="dd/MM/YYYY"
                  selected={selectedDate2}
                  onChange={(date) => {
                    setSelectedDate2(date);
                    setFormattedSelectedDate2(date.toLocaleDateString("en-GB"));
                  }}
                  filterDate={disableDP2}
                  disabled={!selectedDate1}
                />
              </div>
              <div className="manage mb-0">
                <button
                  className="mini-btns border-0 ms-2 btn-orange"
                  onClick={ShowStarredData}
                >
                  <FaStar />
                </button>
                <button
                  className={`mini-btns border-0 ms-2 btn-theme ${
                    selectedChats?.length > 0 ? "" : "isDisabled"
                  }`}
                  onClick={deleteSelectedChats}
                  style={{
                    cursor: selectedChats?.length > 0 ? "pointer" : "no-drop",
                  }}
                >
                  <RiDeleteBin2Fill />
                </button>
              </div>
            </div>
            <div className="search-container">
              {Object?.entries(history) !== undefined &&
              Object?.entries(history)?.length > 0 ? (
                Object?.entries(history)?.map(([date, chats], index) => (
                  <div key={date} className={`${index}`}>
                    <h4 className="pt-2 mb-2">{date}</h4>
                    {chats?.length > 0 &&
                      chats.map((chat) => (
                        <div
                          className="chat-history-link d-flex mb-3"
                          key={chat?.chat_id}
                        >
                          <p
                            className="chat-history-name"
                            onClick={() => historyChat(chat?.chat_id)}
                          >
                            {chat?.chat_name}
                          </p>
                          <label
                            htmlFor={`chat-${chat?.chat_id}`}
                            onClick={() => toggleCheckbox(chat?.chat_id)}
                            style={{ cursor: "pointer" }}
                          >
                            {selectedChats.includes(chat?.chat_id) ? (
                              <FaCircleCheck fontSize={21} />
                            ) : (
                              <CgRadioCheck fontSize={22} />
                            )}
                          </label>
                          <input
                            type="checkbox"
                            name=""
                            id={`chat-${chat?.chat_id}`}
                            className="history-selection"
                            checked={selectedChats.includes(chat?.chat_id)}
                            onChange={() => toggleCheckbox(chat?.chat_id)}
                          />
                        </div>
                      ))}
                  </div>
                ))
              ) : (
                <div className="text-center mt-5">
                  <NoResults />
                  <p className="fs-5 theme-color">No result found</p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SearchHistory;
