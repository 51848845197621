import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Tooltip from "../../components/ui-component/Tooltip";
import "../qus-ans.scss";
import {
  AnsCopy,
  AtSign,
  Check,
  Closeicon,
  Money,
  Regenerate,
  ShareAns,
  XlsFileIcon,
} from "../../components/ui-component/SvgIcon";
import { FaRegStar } from "react-icons/fa";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { API_BASE_LINK } from "../../utils/globalFunctions";
import { fetchThumbStar } from "../../utils/EndPoint";

export const RegenarateBtn = ({ isFetching, triggerFunction, usr_qus }) => {
  return (
    <>
      <Tooltip
        content={"Regenrate"}
        position="bottom"
        fullWidth="w-auto ms-0"
        nowrap="nowrap"
      >
        <button
          aria-label="regenerate"
          disabled={isFetching}
          className={`btns-5 ms-0 ${isFetching ? "isDisabled" : ""}`}
          onClick={() => {
            const fakeEvent = { preventDefault: () => {} };
            triggerFunction(fakeEvent, usr_qus);
          }}
        >
          <Regenerate />
        </button>
      </Tooltip>
    </>
  );
};
export const CopyAns = ({ isFetching, message }) => {
  const [copyStatus, setCopyStatus] = useState(false);

  function copyToClipboard(text) {
    navigator?.clipboard
      .writeText(text)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
    setCopyStatus(true);
    setTimeout(() => {
      setCopyStatus(false);
    }, 2000);
  }

  return (
    <>
      <Tooltip
        content={copyStatus ? "Copied" : "Copy"}
        position="bottom"
        fullWidth="w-auto ms-1"
      >
        <button
          aria-label="copy answer"
          disabled={isFetching}
          className={`btns-5 ${isFetching ? "isDisabled" : ""}`}
          onClick={() => copyToClipboard(message)}
        >
          {copyStatus ? <Check /> : <AnsCopy />}
        </button>
      </Tooltip>
    </>
  );
};
export const DownloadExcel = ({
  hasTable,
  handleDownloadOrShare,
  downloadExcel,
  isFetching,
}) => {
  return (
    <>
      <div className={`${hasTable ? "hide" : ""}`}>
        <Tooltip
          content={"Download Excel"}
          position="bottom"
          fullWidth="w-auto ms-1"
          nowrap="nowrap"
        >
          <button
            aria-label="xls download"
            className={`btns-5 ${hasTable === false ? "isDisabled" : ""} ${
              isFetching ? "isDisabled" : ""
            }`}
            onClick={() => downloadExcel()}
          >
            <XlsFileIcon />
          </button>
        </Tooltip>
      </div>
    </>
  );
};
export const ApiRef = () => {
  return (
    <button
      disabled={true}
      className={`btns-5 ms-1 isDisabled`}
      onClick={() => console.log()}
    >
      <Money />
    </button>
  );
};
// export const Mailers = ({ usr_qus, message }) => {
//   return (
//     <Link
//       to={`mailto:support@pharmacompass.com?subject=${encodeURIComponent(
//         usr_qus
//       )}&body=${encodeURIComponent(message)}`}
//     >
//       <button
//         disabled={true}
//         className={`btns-5 isDisabled`}
//         onClick={() => console.log()}
//       >
//         <img
//           src={Mailer}
//           alt={`button-img`}
//           className=""
//           height={22}
//           width={22}
//         />
//       </button>
//     </Link>
//   );
// };

export const Mailers = ({ usr_qus, message, setAlertPopup, isFetching }) => {
  return (
    <Tooltip
      content={"Send query"}
      position="bottom"
      fullWidth="w-auto ms-1"
      nowrap="nowrap"
    >
      <button
        aria-label="At sign"
        // disabled={false}
        disabled={true}
        className={`btns-5 isDisabled ${isFetching ? "isDisabled" : ""}`}
        onClick={() => setAlertPopup(true)}
      >
        <AtSign />
      </button>
    </Tooltip>
  );
};
export const ShareMsg = ({ handleDownloadOrShare, isFetching }) => {
  return (
    <button
      aria-label="share answer"
      disabled={false}
      className={`btns-5 ms-1 isDisabled ${isFetching ? "isDisabled" : ""}`}
      // onClick={() => handleDownloadOrShare(true)}
      onClick={() => console.log()}
    >
      <ShareAns />
    </button>
  );
};
export const StarIcon = ({ isActive, toggleActive, isFetching }) => {
  // console.log(isActive)
  return (
    <div className="ms-auto">
      <Tooltip
        content={"Mark as important"}
        position="bottom"
        fullWidth="w-auto ms-1"
      >
        <button
          aria-label="star"
          disabled={false}
          onClick={toggleActive}
          className={`btns-5 ms-auto ${isActive ? "checked-btn" : ""} type-2b ${
            isFetching ? "isDisabled" : ""
          }`}
        >
          <FaRegStar fontSize={16} className="icon-filled" />
        </button>
      </Tooltip>
    </div>
  );
};
export const ThumbsUp = ({ isActive, toggleActive, isFetching }) => {
  return (
    <Tooltip
      content={"Good response"}
      position="bottom"
      fullWidth="w-auto ms-1"
    >
      <button
        aria-label="thumbs-up"
        disabled={false}
        onClick={() => {
          toggleActive();
        }}
        className={`btns-5 ${isActive ? "checked-btn" : ""} type-1b ${
          isFetching ? "isDisabled" : ""
        }`}
      >
        <FiThumbsUp fontSize={16} className="icon-filled" />
      </button>
    </Tooltip>
  );
};
export const ThumbsDown = ({
  isActive,
  toggleActive,
  isFetching,
  setResModal,
  setResRecorded,
  resModal,
  resRecorded,
  clickedId,
  contextType,
  isStar,
}) => {
  const [fbName, setFbName] = useState("");
  const BadResponseModal = () => {
    if (resRecorded === true) {
      setResModal(true);
      setResRecorded(false);
    } else return;
  };
  const handleFeedback = () => {
    fetchThumbStar(
      clickedId,
      contextType,
      isStar,
      false,
      true,
      localStorage?.getItem("token"),
      fbName
    );
    setResModal(false);
  };

  return (
    <>
      <Tooltip
        content={"Bad response"}
        position="bottom"
        fullWidth="w-auto ms-1"
      >
        <button
          aria-label="thumbs-down"
          disabled={false}
          onClick={() => {
            toggleActive();
            BadResponseModal();
          }}
          className={`btns-5 ${isActive ? "checked-btn" : ""} type-1b ${
            isFetching ? "isDisabled" : ""
          }`}
        >
          <FiThumbsDown fontSize={16} className="icon-filled" color="" />
        </button>
      </Tooltip>
      {resModal && (
        <>
          <div className="modal-container">
            <div className="modal-outer">
              <div className="modal-head">
                <div className="d-block position-relative mb-3">
                  <button
                    aria-label="close"
                    type="reset"
                    className="ml-end bg-transparent border-0 cursor-pointer position-absolute end_10 top_10 hover-change"
                    onClick={() => {
                      setResModal(false);
                    }}
                  >
                    <Closeicon />
                  </button>
                </div>
                <h2 className="text-center">
                  Provide feedback for this response
                </h2>
              </div>
              <div className="modal-body pt-0">
                <form onSubmit={handleFeedback}>
                  <textarea
                    id=""
                    rows={8}
                    className="w-100 mb-3"
                    placeholder=""
                    name={fbName}
                    onChange={(e) => setFbName(e?.target?.value)}
                  ></textarea>
                  <div className="d-flex justify-content-end gap-3">
                    <button type="button" className="btn btn-outlined-theme">
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-orange"
                      onClick={handleFeedback}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="overlay-fill" onClick={handleFeedback}></div>
        </>
      )}
    </>
  );
};
