import React, { useEffect } from "react";
import "./buttons.scss";
import { CirclePlus } from "../SvgIcon";

export const OutlinedButton = ({ buttonText, clickFunc, mxWid, index }) => {
  useEffect(() => {}, []);
  return (
    <button
      key={index+Date.now()}
      type="button"
      className={`pc-outlined-btn ${mxWid ? "mw-100 text-start mb-2" : ""}`}
      onClick={clickFunc}
    >
      {buttonText}
    </button>
  );
};

export const OutlinedLeftButton = ({ buttonText, clickFunc, mxWid }) => {
  return (
    <button
      type="button "
      className={`pc-outlined-btn d-flex justify-content-between px-2 text-start ${
        mxWid ? "mw-100 text-start mb-2" : ""
      }`}
      onClick={clickFunc}
    >
      {buttonText}
      <CirclePlus />
    </button>
  );
};
